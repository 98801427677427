import { createSelector } from "@reduxjs/toolkit";
import { arrangementsApi } from "../api/arrangementsApi.ts";
import { selectArticlesApiIdMap } from "../selectors/selectArticlesApiIdMap.ts";
import store, { RootState } from "../store.tsx";
import { Arrangement, TableItem } from "../../../../types/qr/arrangements.ts";
import {
  ActiveArrangement,
  ActivePreDiningArrangement,
  getCoversForArrangementAndTableState,
} from "./arrangementsSlice.tsx";
import { selectTableState } from "../api/selectTableState.ts";

export const selectActiveArrangement = createSelector(
  [
    selectTableState,
    (state) => arrangementsApi.endpoints.fetchArrangementsSettings.select()(state).data,
    selectArticlesApiIdMap,
    (state: RootState) => state.global.salesarea.arrangements_demo,
  ],
  (
    tableStateResponse,
    arrangementSettings,
    articleApiIdsMap,
    is_demo
  ): ActiveArrangement | ActivePreDiningArrangement | undefined => {
    // const tableStateResponse = selectTableState(store.getState());
    // const articleApiIdsMap = selectArticlesApiIdMap(store.getState());
    const table_items = tableStateResponse?.data?.data?.table_items ?? [];
    const valid_arrangements = arrangementSettings?.arrangements ?? [];

    const finder = function (arrangement: Pick<Arrangement, "arrangementArticleIds">) {
      return function ({ apiId }: TableItem) {
        // demo mode uses jamezz POS, there are no apiIds. The apiId field is filled with the product id instead.
        if (is_demo && apiId) {
          return arrangement.arrangementArticleIds[apiId];
        }
        const tableItemProduct = articleApiIdsMap[apiId];
        return arrangement.arrangementArticleIds[tableItemProduct?.id];
      };
    };

    // there -should- only be one primary arrangement, but if the waiter makes a mistake there can be more.
    // The primary arrangement is selected by the following two heuristics in this case
    //   - Pick the one with the most covers
    //   - Pick the first on the table
    const tableArrangements = valid_arrangements
      .filter((arrangement) => table_items.find(finder(arrangement)))
      .map((arrangement) => ({
        arrangement,
        covers: getCoversForArrangementAndTableState(arrangement, tableStateResponse.data, articleApiIdsMap, is_demo),
      }))
      .sort((a, b) => b.covers - a.covers)
      .filter(({ covers }) => covers);

    if (!arrangementSettings) {
      return undefined;
    }
    if (tableArrangements.length === 0) {
      const preDiningArrangement = arrangementSettings.pre_dining_arrangement;
      if (preDiningArrangement) {
        return {
          variant: "pre-dining",
          groups: arrangementSettings.arrangement_groups.filter(
            (setting) => preDiningArrangement.arrangementGroupIds[setting.id]
          ),
          ...preDiningArrangement,
        };
      }
      return undefined;
    }
    const tableArrangement = tableArrangements[0];

    const supplementaryArrangements = arrangementSettings.supplementary_arrangements
      .filter((supplementary_arrangement) => table_items.find(finder(supplementary_arrangement)))
      .map((supplementary_arrangement) => ({
        ...supplementary_arrangement,
        covers: getCoversForArrangementAndTableState(
          supplementary_arrangement,
          tableStateResponse.data,
          articleApiIdsMap,
          is_demo
        ),
      }))
      .filter(({ covers }) => covers);

    const supplementaryCovers = supplementaryArrangements.reduce((covers, { covers: count }) => covers + count, 0);

    const activeArrangementGroupIds = Object.assign(
      {},
      ...tableArrangements.map((arr) => arr.arrangement.arrangementGroupIds),
      arrangementSettings.pre_dining_arrangement?.arrangementGroupIds ?? {}
    );
    const groups = arrangementSettings?.arrangement_groups.filter(
      (group) =>
        activeArrangementGroupIds[group.id] ||
        supplementaryArrangements.find(
          (supplementary_arrangement) => supplementary_arrangement.arrangementGroupIds[group.id]
        )
    );

    const allArrangementArticleIds = { ...tableArrangement.arrangement.arrangementArticleIds };
    for (const suppl of supplementaryArrangements) {
      for (const key of Object.keys(suppl.arrangementArticleIds)) {
        allArrangementArticleIds[key] = true;
      }
    }

    return {
      total_covers: tableArrangement.covers + supplementaryCovers,
      own_covers: tableArrangement.covers,
      groups,
      supplementary_arrangements: supplementaryArrangements,
      ...tableArrangement.arrangement,
      arrangementArticleIds: allArrangementArticleIds,
      variant: "regular",
    };
  }
);
