import { PropsWithChildren, useMemo } from "react";
import { useAppSelector } from "../../utils/redux/store";
import { ApplicationType } from "../../utils/useSessionState";
import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from "./MuiViteImportWorkaround";

import convertCustomFonts from "./convertCustomFonts";
import { jamezzApplicationType } from "../../../qr/App";
import _ from "lodash";
import { JSGlobalStyles } from "../../utils/redux/globalSlice.tsx";
import isKiosk from "../../utils/helpers/isKiosk.tsx";

export default function JamezzTheme(props: PropsWithChildren<any>) {
  // TODO: Fix this bug
  // The jamezz theme loads the backoffice custom css --TWICE--
  const appStyling = useAppSelector((state) => state.global.appStyling);
  const wheelchairFriendly = useAppSelector((state) => state.kiosk.wheelchairFriendly);
  const customFontsFromSalesarea = useAppSelector((state) => state.global.salesarea?.custom_fonts);
  const customComponentStylingFromSalesarea = useAppSelector(
    (state) => state.global.salesarea?.custom_component_styling
  );
  const muiThemeMode = useAppSelector((state) => state.global.salesarea?.muiTheme?.mui_theme_mode);
  const muiThemePalettePrimaryMain = useAppSelector(
    (state) => state.global.salesarea?.muiTheme?.mui_theme_palette_primary_main
  );
  const muiThemePalettePrimaryLight = useAppSelector(
    (state) => state.global.salesarea?.muiTheme?.mui_theme_palette_primary_light
  );

  const backgroundColor = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.styling?.mui_theme_background_default
  );

  const paperBackgroundColor = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.styling?.mui_theme_background_paper
  );
  const muiThemeTypographyFontFamily = useAppSelector(
    (state) => state.global.salesarea?.muiTheme?.mui_theme_typography_font_family
  );
  const muiThemeTypographyFontSizeQr = useAppSelector(
    (state) => state.global.salesarea?.muiTheme?.mui_theme_typography_font_size_qr
  );
  const muiThemeTypographyFontSizeKiosk = useAppSelector(
    (state) => state.global.salesarea?.muiTheme?.mui_theme_typography_font_size_kiosk
  );

  const customTheme = useMemo(() => {
    const customTheme = _.cloneDeep(appStyling?.["JS-QrTheme"] ?? {});
    if (customTheme?.palette?.primary && Object.keys(customTheme?.palette?.primary).length === 0) {
      delete customTheme.palette.primary;
    }
    return customTheme;
  }, [appStyling]);

  const customGlobalStyles: JSGlobalStyles | undefined = appStyling?.["JS-QrGlobal"];

  const customFonts = convertCustomFonts(customFontsFromSalesarea);

  if (customComponentStylingFromSalesarea) {
    const customComponentStyling = JSON.parse(customComponentStylingFromSalesarea);
    Object.keys(customComponentStyling).forEach((className) => {
      Object.keys(customComponentStyling[className])
        .filter((cssKey) => customComponentStyling[className][cssKey])
        .forEach((cssKey) => {
          customComponentStyling[className][cssKey] += " !important";
        });

      customFonts.push({ ["." + className]: customComponentStyling[className] });
    });
  }

  const runningInCypress = window.location.host.startsWith("jamezzv2.test") && window.location.href.includes(":5077");
  const defaultTheme = useMemo(() => {
    const kioskButton = {
      borderRadius: "30px",
      padding: "16px 20px",
      fontSize: 28,
      fontWeight: 800,
      minWidth: 150,
      boxShadow: "0 1px 10px 0px rgba(0,0,0,0.4)",
    };
    const defaultTheme: any = {
      palette: {
        mode: muiThemeMode ?? "dark",
        primary: {
          main: muiThemePalettePrimaryMain ?? "#f7844a",
        },
        background: {
          third: "#101010",
        },
      },
      transitions: runningInCypress
        ? {
            duration: {
              shortest: 0,
              shorter: 0,
              short: 0,
              standard: 0,
              complex: 0,
              enteringScreen: 0,
              leavingScreen: 0,
            },
          }
        : undefined,
      typography: {
        fontFamily: muiThemeTypographyFontFamily,
        fontSize: Number(
          jamezzApplicationType.type === ApplicationType.QR
            ? muiThemeTypographyFontSizeQr
            : muiThemeTypographyFontSizeKiosk
        ),
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: customFonts,
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              paddingTop: wheelchairFriendly ? "800px" : undefined,
              ".MuiDialog-paper": {
                // borderRadius: isKiosk() ? "16px" : undefined,
              },
              ".MuiDialog-paperFullScreen": {
                // ...(isKiosk() ? { width: "95%", height: "97%", borderRadius: 48 } : {}),
              },
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              ...(isKiosk() ? { margin: "0 32px" } : {}),
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              ...(isKiosk() ? { margin: 32 } : {}),
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // fontWeight: 800,
              textTransform: "none",
              ...(isKiosk() ? kioskButton : {}),
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h6: { fontWeight: 600 },
          },
        },
      },
    };
    if (paperBackgroundColor && defaultTheme.palette?.background) {
      defaultTheme.palette.background.paper = paperBackgroundColor;
    }
    if (backgroundColor && defaultTheme.palette?.background) {
      defaultTheme.palette.background.default = backgroundColor;
    }

    if (muiThemePalettePrimaryLight && defaultTheme?.palette?.primary) {
      defaultTheme.palette.primary.light = muiThemePalettePrimaryLight;
    }
    return defaultTheme;
  }, [
    muiThemeMode,
    muiThemePalettePrimaryMain,
    runningInCypress,
    muiThemeTypographyFontFamily,
    muiThemeTypographyFontSizeQr,
    muiThemeTypographyFontSizeKiosk,
    customFonts,
    wheelchairFriendly,
    paperBackgroundColor,
    backgroundColor,
    muiThemePalettePrimaryLight,
  ]);

  // live interface
  const previewStyles = useAppSelector((state) => state.global.liveInterfacePreviewStyling);

  const mergedThemeObj = useMemo(() => {
    return _.merge(defaultTheme, props.theme, customTheme);
  }, [defaultTheme, customTheme, props.theme]);

  const theme = useMemo(() => createTheme(mergedThemeObj), [mergedThemeObj]);
  // when using jamezz with live-interface preview styles, force those over saved custom styles.
  const merged = Object.keys(previewStyles).length > 0 ? previewStyles : customGlobalStyles;
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={merged} />
        <CssBaseline enableColorScheme />
        {props.children}
      </ThemeProvider>
    </>
  );
}
