import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { RootState, useAppSelector } from "../store.tsx";
import Article from "../../models/menu/Article.ts";
import { arrangementsApi } from "../api/arrangementsApi.ts";
import { selectArticlesApiIdMap } from "../selectors/selectArticlesApiIdMap.ts";
import { addMilliseconds, differenceInSeconds } from "date-fns";
import OrderArticle from "../../models/order/OrderArticle.ts";
import { useCallback } from "react";
import {
  Arrangement,
  ArrangementGroup,
  ArrangementsState,
  FetchTableStateResponse,
  PreDiningArrangement,
  SupplementaryArrangement,
  TableItem,
} from "../../../../types/qr/arrangements.ts";
import { selectArticleArticlegroupsMap } from "../selectors/selectArticleArticlegroupsMap.ts";
import _ from "lodash";
import { selectActiveArrangement } from "./selectActiveArrangement.tsx";
import { selectTableState } from "../api/selectTableState.ts";
import { setSalesarea } from "../globalSlice.tsx";

const pincode = sessionStorage.getItem("V5.arrangements.pincode");

const initState: ArrangementsState = {
  arrangements: {},
  arrangementGroups: {},
  orderTimeElapsed: true,
  ticket_pincode: {
    status: undefined,
    pincode: pincode ? pincode : "",
  },
};

export const slice = createSlice({
  name: "arrangements",
  initialState: initState,
  reducers: {
    orderTimerElapsed: (state) => {
      state.orderTimeElapsed = true;
    },
    resetOrderTimer: (state) => {
      state.orderTimeElapsed = false;
    },
    userProvidedValidPincode: (state, action: PayloadAction<string>) => {
      state.ticket_pincode.status = undefined;
      state.ticket_pincode.pincode = action.payload;
      sessionStorage.setItem("V5.arrangements.pincode", action.payload);
    },
    statusTicketPincodeChanged: (state, action: PayloadAction<ArrangementsState["ticket_pincode"]["status"]>) => {
      if (action.payload == "PINCODE_IS_REQUIRED") {
        sessionStorage.removeItem("V5.arrangements.pincode");
        state.ticket_pincode.pincode = "";
      }
      state.ticket_pincode.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSalesarea, (state, data) => {
      if (!data.payload.use_arrangements) {
        state.ticket_pincode.pincode = "";
        state.ticket_pincode.status = undefined;
      }
    });
  },
});

function groupAndCountTableItems(table_items: TableItem[]) {
  return _.chain(table_items)
    .groupBy("apiId")
    .mapValues((i) => _.sumBy(i, "count"))
    .omitBy((count) => count <= 0)
    .value();
}

export function getCoversForArrangementAndTableState(
  arrangement: Pick<Arrangement, "arrangementArticleIds"> | undefined,
  tableState: FetchTableStateResponse | undefined,
  articlesApiIdMap: Record<string, Article>,
  is_demo: boolean
) {
  let covers = 0;

  if (arrangement && tableState?.data) {
    tableState.data.table_items.forEach((tableItem) => {
      if (is_demo) {
        covers += tableItem.count;
      } else {
        const tableItemId = tableItem.id ?? articlesApiIdMap[tableItem.apiId]?.id;
        if (arrangement.arrangementArticleIds[tableItemId]) {
          covers += tableItem.count;
        }
      }
    });
  }
  return covers;
}

// TODO: may need to be optimized because memoization is per-article
export const selectArticleCreditCost = createSelector(
  [
    (state: RootState) => state.global.salesarea.use_arrangements,
    (state: RootState, item: OrderArticle) =>
      findArrangementGroupForArticle(
        item.article,
        selectArticleArticlegroupsMap(state),
        selectActiveArrangement(state)?.groups ?? []
      ),
    (_state: RootState, item: OrderArticle) => item,
  ],
  (ayce, ayceGroup, item) => {
    if (ayce && ayceGroup) {
      if (Number.isFinite(ayceGroup.credit_cost)) {
        return Number(ayceGroup.credit_cost) * item.count;
      }
      return ayceGroup.credit_cost;
    }
    return 0;
  }
);

export function useIsArticleAvailableInArrangement(articleArticlegroupsMap: Record<string, string[]>) {
  const activeArrangement = useAppSelector(selectActiveArrangement);

  return useCallback(
    (article: Article, arrangement: Arrangement | PreDiningArrangement) => {
      if (
        activeArrangement?.groups.find((arrangementGroup) => {
          return isArticleAvailableInArrangementGroup(article, arrangementGroup, articleArticlegroupsMap);
        })
      ) {
        return true;
      }

      if ("arrangementArticleIds" in arrangement) {
        return Boolean(arrangement.arrangementArticleIds[article.id]);
      }
      return false;
    },
    [activeArrangement, articleArticlegroupsMap]
  );
}

export function findArrangementGroupForArticle(
  article: Pick<Article, "id">,
  articleArticlegroupsMap: Record<string, string[]>,
  arrangementGroups: ArrangementGroup[]
): ArrangementGroup | undefined {
  return arrangementGroups.find((group) =>
    isArticleAvailableInArrangementGroup(article, group, articleArticlegroupsMap)
  );
}

export function isArticleAvailableInArrangementGroup(
  article: Pick<Article, "id">,
  arrangementGroup: ArrangementGroup,
  articleArticlegroupsMap: Record<string, string[]>
) {
  if (arrangementGroup.articleIdsInGroup[article.id]) {
    return true;
  }

  const articlegroupIds = articleArticlegroupsMap[article.id];

  return (
    articlegroupIds != null &&
    articlegroupIds.some((articlegroupId) => Boolean(arrangementGroup.articlegroupIdsInGroup[articlegroupId]))
  );
}

export const selectLastOrderTime = createSelector(
  [selectTableState, (state: RootState) => state.global.clientTimeDifferenceWithServer],
  (tableStateResponse, clientTimeDifferenceWithServer) => {
    const ticketOrders = tableStateResponse?.data?.data?.ticket?.ticket_orders;
    if (ticketOrders && ticketOrders.length > 0) {
      // make sure it's counted as a round
      // note that reverse mutates and redux doesn't like that. Se we need to make a copy.
      const lastOrderedRound = [...ticketOrders].reverse().find((order) => order.count_as_round);
      if (lastOrderedRound) {
        try {
          return addMilliseconds(new Date(lastOrderedRound.created_at), clientTimeDifferenceWithServer);
        } catch (e) {
          console.error(e);
        }
      }
    }
    return null;
  }
);

export const selectTimeSinceLastOrderInSeconds = createSelector(
  [(state: RootState) => selectLastOrderTime(state), (_state: RootState, recalculate: number) => recalculate],
  (lastOrderTime) => {
    if (lastOrderTime !== null) {
      return differenceInSeconds(new Date(), lastOrderTime);
    }
    return null;
  }
);

export const selectCreatedAtTicket = createSelector(
  [
    (state) =>
      arrangementsApi.endpoints.fetchTableState.select(state.arrangements.ticket_pincode.pincode)(state).data?.data
        ?.ticket?.created_at,
  ],
  (createdAt) => createdAt
);

export const selectArrangementShoppingCartItems = createSelector(
  [
    (state: RootState) => state.shoppingCart.items,
    (state: RootState) => selectActiveArrangement(state)?.groups ?? [],
    selectArticleArticlegroupsMap,
  ],
  (items, activeArrangementGroups, articlegroupsMap) => {
    return {
      inside_arrangement: items.filter((item) => {
        const groupForItem = findArrangementGroupForArticle(item.article, articlegroupsMap, activeArrangementGroups);
        return groupForItem && (Number.isFinite(groupForItem.credit_cost) || groupForItem.credit_cost === "no_cost");
      }),
      outside_arrangement: items.filter((item) => {
        const groupForItem = findArrangementGroupForArticle(item.article, articlegroupsMap, activeArrangementGroups);
        return groupForItem == null || groupForItem.credit_cost === "outside_arrangements";
      }),
    };
  }
);

export const selectArrangementCreditCost = createSelector(
  [
    (state: RootState, items?: OrderArticle[]) => items ?? state.shoppingCart.items,
    (state: RootState) => selectActiveArrangement(state)?.groups,
    selectArticleArticlegroupsMap,
  ],
  (orderArticles: OrderArticle[], arrangementGroups, articleArticlegroupsMap) => {
    let count = 0;
    for (const orderArticle of orderArticles) {
      const arrangementGroup = findArrangementGroupForArticle(
        orderArticle.article,
        articleArticlegroupsMap,
        arrangementGroups ?? []
      );
      if (arrangementGroup && Number.isFinite(arrangementGroup.credit_cost)) {
        count += Number(arrangementGroup.credit_cost) * orderArticle.count;
      }
    }
    return count;
  }
);

export const ARRANGEMENT_FIRST_ROUND = 1;
export const selectCurrentOrderRound = createSelector(
  [(state) => arrangementsApi.endpoints.fetchTableState.select(state.arrangements.ticket_pincode.pincode)(state).data],
  (tableStateResponse) => {
    return (tableStateResponse?.data?.ticket?.ticket_orders ?? []).reduce((sum, ticketOrder) => {
      if (ticketOrder.count_as_round) {
        sum += 1;
      }
      return sum;
    }, 1);
  }
);

export const selectNumberOfActiveArrangements = createSelector(
  [
    selectTableState,
    (state) => arrangementsApi.endpoints.fetchArrangementsSettings.select()(state).data,
    selectArticlesApiIdMap,
  ],
  (tableStateResponse, arrangementSettings, articleApiIdsMap): number => {
    const table_items = groupAndCountTableItems(tableStateResponse?.data?.data?.table_items ?? []);
    const valid_arrangements = arrangementSettings?.arrangements ?? [];

    return valid_arrangements.filter((arrangement) => {
      const find = Object.keys(table_items).find((apiId) => {
        const tableItemProduct = articleApiIdsMap[apiId];
        return arrangement.arrangementArticleIds[tableItemProduct?.id];
      });

      return find;
    }).length;
  }
);

export type ActiveArrangement = Arrangement & {
  total_covers: number;
  own_covers: number;
  groups: ArrangementGroup[];
  supplementary_arrangements: (SupplementaryArrangement & {
    covers: number;
  })[];
  variant: "regular";
};
export type ActivePreDiningArrangement = PreDiningArrangement & { groups: ArrangementGroup[]; variant: "pre-dining" };

export function findArrangementGroupsForArticle(article: Article, groups: ArrangementGroup[]) {
  const articleArticlegroupsMap = selectArticleArticlegroupsMap(store.getState());

  const arrangementGroups = groups.filter((group) =>
    isArticleAvailableInArrangementGroup(article, group, articleArticlegroupsMap)
  );

  if (arrangementGroups.length === 0) {
    console.warn(
      `No arrangement group(s) for article ${article.name}#${article.id} found, it should be impossible to reach this code without a group. Investigate issue.`
    );
  }

  return arrangementGroups;
}

const selectorsApi = {
  activeArrangement: () => selectActiveArrangement(store.getState()),
};
if (window.selectors) {
  Object.assign(window.selectors, selectorsApi);
} else {
  window.selectors = selectorsApi;
}

// Action creators are generated for each case reducer function
export const { resetOrderTimer, orderTimerElapsed, userProvidedValidPincode, statusTicketPincodeChanged } =
  slice.actions;

export default slice.reducer;
