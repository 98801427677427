import { orderSucceeded } from "../shoppingCartSlice";
import { arrangementsApi } from "../api/arrangementsApi";
import { startAppListening } from "../listenerMiddleware";

export function refetchTableStateOnOrderListener() {
  startAppListening({
    predicate: (currentAction, currentState) => {
      return currentState.global.salesarea.use_arrangements && currentAction.type === orderSucceeded.type;
    },
    effect: (_, { dispatch, getState }) => {
      const subscription = dispatch(
        arrangementsApi.endpoints.fetchTableState.initiate(getState().arrangements.ticket_pincode.pincode)
      );
      subscription.refetch();
    },
  });
}
