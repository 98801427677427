import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Articlegroup from "../../models/menu/Articlegroup";
import { dayIndexToDay, getNow, setDateToJamezzTime, TimeSpan } from "../../models/JamezzHours";
import { closestTo, format, isBefore } from "date-fns";
import _ from "lodash";

export const selectSupergroupsMap = createSelector(
  [(state: RootState) => state.menuData.menukaarts],
  (articlegroups) => {
    const supergroups: Record<string, Articlegroup> = {};

    [...articlegroups]
      .sort((a, b) => a.sortKey - b.sortKey)
      .forEach((articlegroup) => {
        if (articlegroup.blocked) {
          return;
        }
        const supergroupId = String(articlegroup.category);

        const orderTimes = articlegroup.orderTimes;
        const now = getNow();
        const dayIndex = now.getDay();
        const day = dayIndexToDay(dayIndex);
        let currentJamezzHour;
        let nearestUpdateDate = new Date();
        if (orderTimes) {
          // @ts-ignore
          let jamezzHours: TimeSpan[] = orderTimes[day.valueOf()];
          const dateKey = format(new Date(), "yyyy-MM-dd");
          if (orderTimes.exceptions[dateKey]) {
            jamezzHours = orderTimes.exceptions[dateKey];
          }

          currentJamezzHour = jamezzHours.find((jamezzHour) => {
            const startDate = setDateToJamezzTime(jamezzHour.startTime);
            const endDate = setDateToJamezzTime(jamezzHour.endTime);
            if (!isBefore(now, endDate)) {
              return false;
            }
            if (!isBefore(startDate, now)) {
              nearestUpdateDate = closestTo(now, [nearestUpdateDate, startDate]) ?? nearestUpdateDate;
              return false;
            }
            nearestUpdateDate = closestTo(now, [nearestUpdateDate, endDate]) ?? nearestUpdateDate;
            return true;
          });
        } else {
          currentJamezzHour = true;
        }

        if (currentJamezzHour && articlegroup.category) {
          if (supergroups[supergroupId]) {
            if (!supergroups[supergroupId].translations) {
              if (articlegroup.translations) {
                supergroups[supergroupId].translations = articlegroup.translations;
              }
            } else {
              Object.keys(supergroups[supergroupId].translations).forEach((locale) => {
                if (
                  !supergroups[supergroupId].translations[locale]?.categorie &&
                  articlegroup.translations?.[locale]?.categorie
                ) {
                  supergroups[supergroupId].translations[locale].categorie =
                    articlegroup.translations[locale].categorie;
                }
              });
            }

            supergroups[supergroupId].sortKey = Math.min(supergroups[supergroupId].sortKey, articlegroup.sortKey);

            // TODO something with translations Jhorie
          } else {
            const supergroup: Articlegroup = {
              id: supergroupId,
              name: articlegroup.category,
              images: [],
              sortKey: articlegroup.sortKey,
              showMaxNumberOfItems: 0,
              description: "",
              numberOfColumns: 1,
              updatedAt: "",
              showInCategoryMenu: true,
              blocked: false,
              minWidthPerArticle: 300,
              isVisibleInJamezz: true,
              settings: null,
              disableOrdering: false,
              parentId: null,
              customizable_texts: [],
              translations: _.cloneDeep(articlegroup.translations),
            };
            supergroups[supergroupId] = supergroup;
          }
        }
      });

    return supergroups;
  }
);
